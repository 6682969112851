.selectDisabled {
  cursor: not-allowed !important;
}

.title {
  font-size: 18px;
  color: #272522;
  font-weight: 700;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.view_performance_navbar {
  border-bottom: 1px solid #e7e6e4;
  margin: 0 -24px 0 -5px;
  width: 103%;
}

.margin_left {
  margin-left: 19px;
}

.save_button {
  background-color: #493ab1 !important;
}

.stats_card {
  margin-bottom: 16px;
  flex: 1;
}

.save_button {
  background-color: #493ab1 !important;
}

.stats_wrapper {
  display: flex;
  gap: 26px;
  margin-bottom: 20px;
  align-items: center;
}

.light_grey_text {
  color: #8a8f9d;
  font-size: 14px;
  font-weight: 400;
}

.faqText {
  margin-left: 12px;
}

.faqCta {
  margin-top: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #0854fe;
}

.faqCard {
  display: flex;
  border: 1px solid #dbe0ef;
  border-radius: 12px;
  padding: 15px;
  flex: 1;
  width: 90%;
  margin: 10px 15px 10px 0;
  position: relative;
}

.trackIcon {
  background-color: rgb(238, 236, 249);
  color: rgb(73, 58, 177);
  border-radius: 10px;
  font-size: 24px;
}

.greyText {
  font-weight: 400;
  font-size: 14px;
  color: #aaacb2;
  line-height: 16px;
}

.faqCta {
  margin-top: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgb(73, 58, 177);
}

.fw_bolder {
  font-weight: 500;
}

.card {
  padding: 20px 15px 10px 15px;
  margin: 15px 5px 15px 5px;
  width: fit-content;
}

.sticky {
  padding-bottom: 20px;
  position: sticky;
  padding: 45px 0 0 0;
  top: 0px;
  z-index: 100;
  background-color: #ffffff;
}

.icon_img {
  margin-right: 10px;
  border: 1px solid grey;
  padding: 5px;
  border-radius: 5px;
  width: 50px;
  height: 30px;
  object-fit: contain;
}

.clear_filter {
  margin: 0 0 20px 0;
  cursor: pointer;
}

.dm_font {
  font-family: DM Sans;
}

.flex_row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.card_header {
  color: #6c5dd3;
  font-size: 18px;
  line-height: 24px;
  margin: 20px 0 -10px 0;
  font-weight: 800;
  color: #212121;
}

.black_header {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
}

.checklist_row {
  justify-content: flex-start;
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0 10px 0;
  align-items: center;
}

.checkbox {
  margin: 0 10px 0 10px;
}

.checkboxInput {
  height: 14px !important;
  width: 14px !important;
}

.form_card {
  padding: 20px;
  border-radius: 4px;
  border: solid 1px #e5e3dd;
  background-color: white;
  width: 100% !important;
}

.row {
  margin: 15px 0 30px 0;
}

.full_width_input {
  display: inline-block;
  width: 100%;
}

.black_small_text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #666666;
}

.whatsapp_icon {
  width: 16px !important;
  margin-left: 2px;
  color: #6c5dd3 !important;
  padding-bottom: 3px;
}

.whatsapp_number {
  white-space: pre;
}

.searchIcon {
  display: block;
  margin-right: -10px;
}
.searchIcon svg {
  width: 50px;
}

.modal {
  background-color: white;
  padding: 30px 40px;
}

.modal_title {
  font-weight: 600;
  font-size: 24px;
}

.modal_subtitle1 {
  font-weight: 500;
  font-size: 20px;
  margin: 8px 0 4px;
}

.modal_subtitle2 {
  font-size: 15px;
  letter-spacing: 0.7px;
}

.modal_question {
  color: #555;
}

.modal_answer {
  font-size: 19px;
  margin: 4px 0 8px;
}

.modal_close {
  float: right;
  font-size: 36px;
  font-weight: 400;
  top: 0;
  line-height: 1em;
  cursor: pointer;
  color: #555;
}

.modal_close:hover {
  color: #333;
}
.launch {
  color: #6c5dd3 !important;
}
.close {
  display: block;
  float: right;
  margin-top: -20px;
  font-size: 25px;
  cursor: pointer;
}

hr.bankDetailsSeparator {
  margin-top: 0px;
  margin-bottom: 24px;
}

.gst_toggle_container {
  margin: 24px 0;
  max-width: 50%;
  min-width: 400px;
}

.gst_toggle_row {
  display: flex;
  align-items: center;
}

.gst_toggle_row.info_icon_row {
  align-items: flex-start;
}

.gst_toggle_title {
  font-size: 16px;
  font-weight: 600;
  opacity: 0.9;
  letter-spacing: -0.6px;
  margin-right: auto;
}

.gst_toggle_label {
  display: block;
}

.gst_toggle_switch {
  margin-left: 4px;
}

.gst_toggle_icon {
  width: 18px !important;
  height: 18px !important;
  margin-top: 3px;
  margin-right: 6px;
}

.gst_toggle_info {
  font-size: 15px;
  border: 1px solid #ddd;
  background-color: #fafafa;
  padding: 12px 16px;
  margin: 12px 0;
  border-radius: 12px;
}

.gst_toggle_info.gst_status_1 {
  /* green: #ccf0da, #b4e3ce */
  background-color: #ccf0da;
  border-color: #b4e3ce;
}

.gst_toggle_info.gst_status_2 {
  /* red: #ffedcf, #ddd7b9 */
  background-color: #ffedcf;
  border-color: #ddd7b9;
}

.all_transaction_mobile_footer_added_booking {
  background-color: #e5fff7 !important;
}
.all_transaction_mobile_footer_processed {
  background-color: #e5fff7 !important;
}
.all_transaction_mobile_footer_failed {
  background-color: #fbebea !important;
}
.all_transaction_mobile_footer_pending {
  background-color: #fff7e5 !important;
}
.all_transaction_mobile_footer_refunded {
  background-color: #e5fff7 !important;
}
.refund_history_credit_note {
  cursor: text !important;
  color: #000000 !important;
  text-decoration: none !important;
}
@media screen and (max-width: 780px) {
  .sticky {
    padding-bottom: 0;
    padding-left: 15px;
    position: relative;
  }

  .bottom_bar_payout_details {
    bottom: 80px !important;
  }

  .gst_toggle_container {
    margin: 24px 0;
    width: 100%;
    min-width: 0;
    max-width: 100%;
  }

  .card_header {
    font-size: 14px;
  }
  .header {
    padding-bottom: 16px;
  }

  .gst_toggle_container {
    margin: 24px 0;
    width: 100%;
    min-width: 0;
    max-width: 100%;
  }

  .form_card {
    padding: 5px 10px;
    padding-bottom: 20px;
  }

  .save_button {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .mobileParent form {
    padding-left: 15px !important;
  }

  .save_button {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .bookedSessionMobile {
    width: 42vw;
    min-width: 100px !important;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .bookedInputMobile > div {
    background-color: #ffff !important;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }
  .bookedInputMobile > div::before {
    border-bottom: none !important;
  }
  .bookedInputMobile > div::after {
    border-bottom: none !important;
  }
  .bookedSessionMobile:after {
    border-bottom: unset !important;
  }
  .listItem {
    width: 100vw !important;
    padding: 0 !important;
  }
  .filterMobile {
    margin-top: 10px !important;
    margin-right: -5px !important;
    margin-bottom: 35px !important;
  }
  /* .listItem div div button{
        position: absolute;
        top: 505px;
        right: 311px;
    } */

  .bookedSessionMobile > div::before {
    border-bottom: none !important;
  }
  .bookedSessionMobile > div::after {
    border-bottom: none !important;
  }

  .bookedSessionMobile input {
    background-color: #ffff !important;
  }

  .bookedSessionMobile > div > div {
    background-color: #ffff !important;
  }

  .clearFilterMobile {
    padding-left: 15px;
  }

  .whatsapp_number {
    white-space: normal;
  }

  .listItem > div:nth-child(2) > div {
    border: none;
  }

  .modal {
    padding: 15px;
  }

  .faqCard {
    margin: 10px 0;
    width: 100%;
  }

  .trackIcon {
    padding: 7px 7px 2px 7px;
  }

  .view_performance_title {
    font-size: 14px;
  }
}
