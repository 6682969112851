.modalContainer {
  border-radius: 12px !important;
  width: 600px !important;
  max-width: 600px !important;
}

.reviewSelectionContainer {
  border-radius: 12px !important;
  width: 1000px !important;
  max-width: 1000px !important;
}

.modalContainerHeight {
  height: 200px;
}

.footer {
  height: 59.5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  border-top: 1.5px solid #e7e6e4;
}

.tooltip {
  font-size: 18px !important;
  margin-top: -3px;
  opacity: 0.6;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 0 24px;
  }
}
